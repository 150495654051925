import React, { Component } from 'react';
import './ThesisCard.css';

/*<div className='thesis-cover-title'>
  <p>Brain connectivity and sensory stimulation in disorders of consciousness</p>
  <p className='cover-title-name'>Lizette Heine</p>
</div>*/


class ThesisCard extends Component {
  render() {
    return (
      <div className="ThesisCard">
        <div className='image'>
          <div className='thesis-cover-image'>
            <a href="https://orbi.uliege.be/handle/2268/201496" target="_blank" rel="noopener noreferrer">
              <img alt='Thesis back page' className="thesis-cover" src='thesis-back-page.jpg'/>
              <img alt='Thesis back page' className="thesis-cover" src='thesis-front-page.jpg'/>
            </a>
          </div>
          <a href="https://orbi.uliege.be/handle/2268/201496" target="_blank" rel="noopener noreferrer"><h4 className='thesis-title'>
            Brain connectivity and sensory stimulation in patients with disorders of consciousness</h4>
          </a>
          <h5 className='doctorate-info'>European Doctorate defended September 2016</h5>
          <p className='thesis-summary'>This thesis explores brain connectivity and sensory stimulation in patients with disorders of consciousness (DOC). These are serious conditions where massive brain damage can lead to a dissociation between arousal and awareness (e.g., UWS and MCS).</p>
          <p className='thesis-summary'>Part I explores brain connectivity. We highlight that brain function and structure are intimately related to each other, and to consciousness. The decrease in brain function can be used to distinguish between the clinically indicated states of consciousness.
          </p>
          <p className='thesis-summary'>Part II evaluates passive sensory stimulations. Preferred stimuli may have the power to momentarily enhance brain function, and behavioral responses.
          </p>
        </div>
      </div>
    );
  }
}

export default ThesisCard;
