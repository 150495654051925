import React, { Component } from 'react';
import './ContentSection.css';

class ContentSection extends Component {
  render() {
    return (
      <div className="ContentSection" style={this.props.style}>
        <div className='data'>
          <strong className='title'>{this.props.title}</strong>
          <p className='details'>{this.props.details}</p>
        </div>
        {this.props.content}
      </div>
    );
  }
}

export default ContentSection;
