import React, { Component } from 'react';
import './TeachingExperience.css';

function TeachingEntry(props) {
  return (<div className='TeachingEntry'>
    <div className="time-range-column">
      <p className='time-range'>{props.time}</p>
    </div>
    <div className="details-column">
      <p className='activity'>{props.activity}</p>
    </div>
  </div>);
}

const entries = [
  {
    time: 'Since 2017',
    activity: 'Teaching second year master course “Biomedical English” – Université Claude Bernard Lyon 1',
    title: null,
    location: null
  },
  {
    time: 'Since 2016',
    activity: "Supervision of Master's students, Université Claude Bernard Lyon 1",
    title: null,
    location: null
  },
  {
    time: 'May 2016',
    activity: 'Co-organization of a CRS-R workshop: workshop on theoretical and practical training of the coma recovery scale revised at the Coma Science Group',
    title: null,
    location: null
  },
  {
    time: '2014',
    activity: "Supervised one Master's student, University of Liège",
    title: null,
    location: null
  },
  {
    time: '2009-2011',
    activity: 'Student tutor, Maastricht University',
    title: null,
    location: null
  },
];

class TeachingExperience extends Component {
  render() {
    const details = entries.map((entry, index) => <TeachingEntry key={index} {...entry}/>);

    return (
      <div className="TeachingExperience">
        {details}
      </div>
    );
  }
}

export default TeachingExperience;
