import React, { Component } from 'react';
import './AcademicHistory.css';

function HistoryEntry(props) {
  return (<div className='HistoryEntry'>
    <div className="time-range-column">
      <p className='time-range'>{props.time}</p>
    </div>
    <div className="details-column">
      <p className='activity'>{props.activity}</p>
      {props.title && <p className='title'>{props.title}</p>}
      {props.location}
    </div>
  </div>);
}

const entries = [
  {
    time: 'Since 2016',
    activity: 'Post-doctoral researcher',
    title: null,
    location: (<p><a href="https://crnl.univ-lyon1.fr/index.php/en/Research/CRNL-teams-2016-2020/18" target="_blank" rel="noopener noreferrer">Audition, Cognition, and Psychoacoustics team (CAP)</a> - Lyon Neuroscience Research Centre, University Claude Bernard 1, France.</p>)
  },
  {
    time: '2016',
    activity: 'Visiting Researcher',
    title: null,
    location: (<p>International Vegetative State and Consciousness Science Institute, Hangzhou Normal University, China</p>)
  },
  {
    time: '2016',
    activity: 'European Ph.D. in Biomedical Sciences',
    title: 'Brain connectivity and sensory stimulation in patients with disorders of consciousness',
    location: (<p><a href="http://www.coma.ulg.ac.be/" target="_blank" rel="noopener noreferrer">Coma Science Group</a>, GIGA Research Centre, University of Liège and University Hospital of Liège, Liège, Belgium.</p>)
  },
  {
    time: '2015',
    activity: 'Secondment',
    title: null,
    location: (<p><a href="https://crnl.univ-lyon1.fr/index.php/en/Research/CRNL-teams-2016-2020/18" target="_blank" rel="noopener noreferrer">Audition, Cognition, and Psychoacoustics team (CAP)</a> - Lyon Neuroscience Research Centre, University Claude Bernard 1, France.</p>)
  },
  {
    time: '2014',
    activity: 'Short stay',
    title: 'Analysis of resting state functional connectivity in congenitally blind',
    location: (<p>Department of Neuroscience & Pharmacology, Panum Institute. University of Copenhagen, Denmark</p>)
  },
  {
    time: '2013',
    activity: 'Master of Science in Neuropsychology',
    title: null,
    location: 'Faculty of Psychology and Neuroscience, Maastricht University, Netherlands'
  },
  {
    time: '2011',
    activity: 'Bachelor of Science in Biological Psychology',
    title: null,
    location: 'Faculty of Psychology and Neuroscience, Maastricht University, Netherlands'
  },
  {
    time: '2010',
    activity: 'Erasmus Semester',
    title: null,
    location: 'University of Warsaw, Poland'
  }
];

class AcademicHistory extends Component {
  render() {
    const history = entries.map((entry, index) => <HistoryEntry key={index} {...entry}/>);

    return (
      <div className="AcademicHistory">
        {history}
      </div>
    );
  }
}

export default AcademicHistory;
