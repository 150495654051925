import React, { Component } from 'react';
import './AboutCard.css';

class AboutCard extends Component {
  render() {
    return (
      <div className="AboutCard">
        <p>Hello! My name is Lizette Heine and I am a neuroscientist.</p>
        <p>Coming from research on consciousness, with patients who suffer from disorders of consciousness I am especially interested in sensory perception. One of my current topics looks for ways to improve brain function, attention and responsiveness using emotional and salient stimuli, for example preferred music. My research-interests are however not limited to work on patients, and research topics have, among others, also involved acoustic perception in healthy subjects and functional connectivity in the congenitally blind.</p>
        <p>Throughout my research I have employed multiple methods, main ones being (f)MRI, EEG, and PET, which are very often combined with behavioral assessments (neuropsychological metrics in the case of patient work). I generally analyze my data using Python, R, or Matlab.</p>
      </div>
    );
  }
}

export default AboutCard;
