import React, { Component } from 'react';
import './About.css';

class About extends Component {
  static defaultProps = {
    onClick: () => {
      console.log('clicked');
    }
  }

  render() {
    return (
      <div className="About">
        <div className="header">
          <h1 className='name'>Lizette Heine</h1>
          <p className='details'>Neuroscientist</p>
        </div>

        <div className='link-icons'>
          <div className='link-icon google-scholar'>
            <a target="_blank" rel="noopener noreferrer" href="https://scholar.google.fr/citations?user=-3lbdgkAAAAJ&hl=en">
              <img alt='Google Scholar' src="google-scholar.png"/>
            </a>
          </div>

          <div className='link-icon google-scholar'>
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/lizetteheine">
              <svg className='linkedin'>
                <use xlinkHref="/brands.svg#linkedin"/>
              </svg>
            </a>
          </div>

          <div className='link-icon google-scholar'>
            <a target="_blank" rel="noopener noreferrer" href="https://www.researchgate.net/profile/Lizette_Heine">
              <svg className='researchgate'>
                <use xlinkHref="/brands.svg#researchgate"/>
              </svg>
            </a>
          </div>

          <div className='link-icon google-scholar'>
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/LizetteH">
              <svg className='github'>
                <use xlinkHref="/brands.svg#github"/>
              </svg>
            </a>
          </div>
        </div>
        <div className='buttons'>
          <button className='btn btn-primary' onClick={this.props.onClick}>Get in touch</button>
        </div>
        <div className="logo">
          <img className='my-brain' alt='brain' src='brain.png'/>
        </div>
      </div>
    );
  }
}

export default About;
