import React, { Component } from 'react';
import Modal from 'react-modal';
import About from './About.js';
import ContentSection from './ContentSection.js';
import AboutCard from './AboutCard.js';
import SelectedPublications from './SelectedPublications.js';
import ThesisCard from './ThesisCard.js';
import AcademicHistory from './AcademicHistory.js';
import TeachingExperience from "./TeachingExperience.js";
import ContactForm from "./ContactForm.js";
import './Layout.css';

/**

blue1 216A9F
blue2 528FA3
lightpurple #9786BD
darkblue #18144F
yelloworange #FEBE0C
pink #F4758E
brightpink #EB438A
reddish #EB1C25
orange: #D95C30

*/

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    backgroundColor       : '#F9F9F9',
    maxWidth: '700px',
    width: '80%',
    padding: '50px 25px 25px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)'
  }
};


class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactModalIsOpen: false
    };

  }

  openModal = () => {
    this.setState({contactModalIsOpen: true});
  }

  closeModal = () => {
    this.setState({contactModalIsOpen: false});
  }

  render() {
  	const sections = [
  		{
  			content: (
  				<AboutCard/>
			),
			style: {
				backgroundColor: '#216A9F',
        color: 'white'
			},
			title: 'About',
			details: null,
			link: null
  		},

  		{
  			content: (
  				<SelectedPublications/>
			),
			style: {
				backgroundColor: '#9786BD',
        color: 'white'
			},
			title: 'Selected Publications',
			details: null,
			link: null,
  		},

  		{
  			content: (
  				<ThesisCard/>
			),
			style: {
				backgroundColor: '#FEBE0C',
        color: 'black'
			},
			title: 'My Ph.D. Thesis',
			details: null,
			link: null // TODO: Add dropbox link
  		},

  		{
  			content: (
  				<AcademicHistory/>
			),
			style: {
				backgroundColor: '#F4758E',
        color: 'black'
			},
			title: 'My Background',
			details: null,
			link: null // TODO: Add dropbox link
  		},

  		{
  			content: (
  				<TeachingExperience/>
			),
			style: {
				backgroundColor: '#528FA3',
        color: 'white'
			},
			title: 'My Teaching Experience',
			details: null,
			link: null // TODO: Add dropbox link
  		}
  	];

  	const contentSections = sections.map((section, index) => {
  		return (
  			<ContentSection key={index} {...section}/>
		  );
  	});

    return (
      <div className="Layout">
        <div className='sidebar'>
        	<About onClick={this.openModal}/>
        </div>
        <div className='content'>
        	{contentSections}
        </div>
        <Modal
          isOpen={this.state.contactModalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Contact"
        >
          <button className='modal-close-button' onClick={this.closeModal}>close</button>
          <ContactForm/>
        </Modal>
      </div>
    );
  }
}

export default Layout;
