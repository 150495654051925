import React, { Component } from 'react';
import './SelectedPublications.css';

function PublicationEntry(props) {
  return (<div className='PublicationEntry'>
    <div className="time-range-column">
      <p className='time-range'>{props.year}</p>
    </div>
    <div className="details-column">
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        <p className='title'>{props.title}</p>
      </a>
      <p className='authors'>{props.authors}</p>
      <p className='journal'>{props.journal}</p>
    </div>
  </div>);
}


const entries = [
  {
    title: 'Personally relevant stimuli during assessment of patients with disorders of consciousness',
    authors: 'Heine & Perrin',
    journal: 'Journal of Rehabilitation Medicine',
    link: 'https://www.medicaljournals.se/jrm/content/abstract/10.2340/16501977-2342',
    year: '2018'
  },
  {
    title: "The repetition of behavioral assessments in diagnosis of disorders of consciousness",
    authors: "Wannez, Heine et al.",
    journal: "Annals of Neurology",
    link: "https://onlinelibrary.wiley.com/doi/abs/10.1002/ana.24962",
    year: '2017'
  }, {
    title: "Function–structure connectivity in patients with severe brain injury as measured by MRI‐DWI and FDG‐PET",
    authors: "Annen & Heine et al.",
    journal: "Human Brain Mapping",
    link: "https://pdfs.semanticscholar.org/70ca/a515aff2aa71060fdc19e5d3cd89d6511c16.pdf",
    year: '2016'
  }, {
    title: "Exploration of functional connectivity during preferred music stimulation in patients with disorders of consciousness",
    authors: "Heine et al.",
    journal: "Frontiers in Psychology",
    link: "https://www.frontiersin.org/articles/10.3389/fpsyg.2015.01704/full",
    year: '2015'
  },
  {
    title: 'Prevalence of increases in functional connectivity in visual, somatosensory and language areas in congenital blindness',
    authors: 'Heine et al.',
    journal: 'Frontiers in Neuroanatomy',
    link: 'https://www.frontiersin.org/articles/10.3389/fnana.2015.00086/full',
    year: '2015'
  },
  {
    title: 'Diagnostic precision of PET imaging and functional MRI in disorders of consciousness: a clinical validation study',
    authors: 'Stender et al.',
    journal: 'The Lancet',
    link: 'https://www.sciencedirect.com/science/article/pii/S0140673614600428',
    year: '2014'
  },
  {
    title: "Resting state networks and consciousness",
    authors: "Heine et al.",
    journal: "Frontiers in Psychology",
    link: "https://www.frontiersin.org/articles/10.3389/fpsyg.2012.00295/full",
    year: '2012'
  }
];

class SelectedPublications extends Component {
  render() {
    const details = entries.map((entry, index) => <PublicationEntry key={index} {...entry}/>);

    return (
      <div className="SelectedPublications">
        {details}
        <a href="https://scholar.google.fr/citations?hl=en&user=-3lbdgkAAAAJ" target="_blank" rel="noopener noreferrer">
          <h3>See more</h3>
        </a>
      </div>
    );
  }
}

export default SelectedPublications;
