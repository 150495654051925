import React, { Component } from "react";
import "./ContactForm.css";

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = { name: "", email: "", message: "", disabled: false };
  }

  /* Here’s the juicy bit for posting the form submission */

  handleSubmit = e => {
    this.setState({
      disabled: true,
      finished: true,
    });

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state })
    })
      .then(() => {
        console.log("Form posted successfully");
        console.log(this.state);
      })
      .catch(error => {
        throw new Error(error);
      });

    e.preventDefault();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { name, email, message } = this.state;
    return (
      <form className='ContactForm' onSubmit={this.handleSubmit}>
        <fieldset>
          <input
            type="text"
            name="name"
            placeholder="Your name"
            tabIndex="1"
            required
            autoFocus
            value={name}
            disabled={this.state.disabled}
            onChange={this.handleChange}
          />
        </fieldset>
        <fieldset>
          <input
            type="email"
            name="email"
            placeholder="Your email"
            tabIndex="2"
            required
            value={email}
            disabled={this.state.disabled}
            onChange={this.handleChange}
          />
        </fieldset>
        <fieldset>
          <textarea
            name="message"
            placeholder="Type your message here..."
            tabIndex="3"
            required
            value={message}
            disabled={this.state.disabled}
            onChange={this.handleChange}
          />
        </fieldset>
        {this.state.finished ?
        <h3>
          Thank you for your message!
        </h3>
          :
          <button className='btn btn-primary' disabled={this.state.disabled} type="submit">Send</button>
        }
      </form>
    );
  }
}

export default ContactForm;
